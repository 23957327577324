import { paths } from "@minuthq/minut-api-types/schema"

import { IFilterCard } from "src/data/filters/types/filterTypes"
import { THome, TThermostatMode } from "src/data/homes/types/homeTypes"

export const INTEGRATION_SECRET_DEFAULT = ""

export type TIntegration =
  paths["/integrations"]["get"]["responses"]["200"]["content"]["application/json"]["integrations"][number]

export type TIntegrationType = TIntegration["type"]

export type TClientCredentialsResponse =
  paths["/integrations/auth/oauth2/client_credentials"]["post"]["responses"]["200"]["content"]["application/json"]

export type TPostGuestsCSVResponse =
  paths["/organizations/{organization_id}/csv/guests"]["post"]["responses"]["201"]["content"]["application/json"]

export type TPostGuestsCSVRowError =
  paths["/organizations/{organization_id}/csv/guests"]["post"]["responses"]["400"]["content"]["application/json"]

export type TPostGuestsCSVAuthError =
  paths["/organizations/{organization_id}/csv/guests"]["post"]["responses"]["403"]["content"]["application/json"]

export type TntegrationAuthCredentialResponse =
  paths["/integrations/auth/credentials"]["get"]["responses"]["200"]["content"]["application/json"]

export type TntegrationAuthCredential = NonNullable<
  paths["/integrations/auth/credentials"]["get"]["responses"]["200"]["content"]["application/json"]["credentials"]
>[number]

export type TIntegrationEntities =
  paths["/integrations/{integration_identifier}/entities"]["get"]["responses"]["200"]["content"]["application/json"]["entities"]

export type TIntegrationEntity = TIntegrationEntities[number] & {
  id: string
}

export type TFetchIntegrationEntityError400 =
  paths["/integrations/{integration_identifier}/entities"]["get"]["responses"]["400"]["content"]["application/json"]

export type TIntegrationLinks =
  paths["/integrations/{integration_identifier}/links"]["get"]["responses"]["200"]["content"]["application/json"]["links"]

export type TIntegrationLinksQuery =
  paths["/integrations/{integration_identifier}/links"]["get"]["parameters"]["query"]

export type TIntegrationLink = TIntegrationLinks[number]

type TStaticIntegrationId = "guest-importer"

export type TIntegrationStatic = Omit<
  TIntegration,
  | "type"
  | "integration_identifier"
  | "logo"
  | "auth"
  | "configured"
  | "connection_status"
  | "pagination_available"
> & {
  type: TIntegrationType
  static: true
  integration_identifier: TStaticIntegrationId
  iconComponent: React.ReactNode
  configured?: boolean
}

export type TIntegrationResponse =
  paths["/integrations"]["get"]["responses"]["200"]["content"]["application/json"]

export type TIntegrationId =
  paths["/integrations/auth/oauth2/client_credentials"]["post"]["responses"]["200"]["content"]["application/json"]["integration"]

export type TIntegrationHomeLink =
  paths["/integrations/{integration_identifier}/homes"]["get"]["responses"]["200"]["content"]["application/json"]["homes"][number]

// In usgae, we expect to pass an array of home_ids which will be transformed into a string that the backend expects
// hence the need for the overide below
export type TIntegrationHomeLinksFilter = Omit<
  paths["/integrations/{integration_identifier}/homes"]["get"]["parameters"]["query"],
  "owner_type" | "owner_id" | "home_ids"
> & {
  home_ids?: string[]
}

type TIntegrationHomeLinksSortPartial = {
  id: NonNullable<
    paths["/integrations/{integration_identifier}/homes"]["get"]["parameters"]["query"]["sort"]
  >
  order: NonNullable<
    paths["/integrations/{integration_identifier}/homes"]["get"]["parameters"]["query"]["sort_order"]
  >
}

export type TIntegrationHomeLinksSort =
  Required<TIntegrationHomeLinksSortPartial>

export enum OwnerType {
  USER = "user",
  ORGANIZATION = "organization",
}

export type TIntegrationFilter = IFilterCard & {
  id: TIntegrationType
  value: TIntegrationType
}

export type TAvailableThermostatMode = NonNullable<
  NonNullable<TIntegrationEntity["devices"]>[number]["available_modes"]
>[number]

export type TThermostatSettings = {
  mode?: TThermostatMode
  turnOnTime?: THome["thermostat_turn_on_in_minutes_before_checkin"]
  setPoints?: THome["thermostat_temperature_setpoint_on_checkin"]
  commonAvailableModes?: TAvailableThermostatMode[]
}

export type EventContext = "integration_page" | "create_home"

//Replacing the owner_type in the query with the OwnerType enum that we use in FE
export type TGetLinksParams = Omit<TIntegrationLinksQuery, "owner_type"> & {
  owner_type: OwnerType
} & {
  integration: TIntegrationId
}

export type TIntegrationConnectionErrorResponse = NonNullable<
  paths["/integrations/auth/secret"]["post"]["responses"]["400"]["content"]["application/json"]
>

export type TClientCredentialsMultiSecretResponse =
  paths["/integrations/auth/multisecret"]["post"]["responses"]["201"]["content"]["application/json"]

export type TClientCredentialsSecretResponse =
  paths["/integrations/auth/secret"]["post"]["responses"]["200"]["content"]["application/json"]

export type TClientCredentialsClientCredentialsResponse =
  paths["/integrations/auth/oauth2/client_credentials"]["post"]["responses"]["200"]["content"]["application/json"]
